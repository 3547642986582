<template>
  <v-container fluid fill-height align-start>
    <v-row
      :style="{ height: '100%' }"
      justify="center"
      v-if="$store.getters.requiringMyAttention > 0"
    >
      <v-col cols="12" sm="6" md="4" lg="3" v-if="forms.length">
        <v-card height="100%">
          <v-card-title class="msaBlue white--text">
            Forms
            <v-badge
              inline
              class="ml-2"
              :content="forms.length"
              :value="forms.length"
              color="red"
            >
            </v-badge>
          </v-card-title>
          <v-card-text class="px-0">
            <v-virtual-scroll
              :items="forms"
              :height="height"
              :item-height="threeLineHeight"
            >
              <template v-slot:default="{ item, index }">
                <v-list-item :key="index" @click="goToForm(item)">
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold text-body-2">
                      {{ item.userEnteredLabel }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="font-italic text-caption">
                      <div class="msaBlue--text">{{ item.status }}</div>
                      <div>
                        Created Date:
                        {{ item.createdOnMobileAt | filterAsLocalDate }}
                      </div>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-list-item-action>
                </v-list-item>
                <v-divider></v-divider>
              </template>
            </v-virtual-scroll>
          </v-card-text>
          <v-card-actions class="msaBlue">
            <v-btn text dark block @click="goToForms()" class="text-right">
              <v-spacer></v-spacer> view all <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3" v-if="documents.length">
        <v-card height="100%">
          <v-card-title class="msaBlue white--text">
            Documents
            <v-badge
              inline
              class="ml-2"
              :content="documents.length"
              :value="documents.length"
              color="red"
            >
            </v-badge>
          </v-card-title>
          <v-card-text class="px-0">
            <v-virtual-scroll
              :items="documents"
              :height="height"
              :item-height="twoLineHeight"
            >
              <template v-slot:default="{ item, index }">
                <v-list-item :key="index" @click="goToDocument(item)">
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold text-body-2">
                      {{ item.documentName }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-list-item-action>
                </v-list-item>
                <v-divider></v-divider>
              </template>
            </v-virtual-scroll>
          </v-card-text>
          <v-card-actions class="msaBlue">
            <v-btn text dark block @click="goToDocuments()" class="text-right">
              <v-spacer></v-spacer> view all <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3" v-if="tasks.length">
        <v-card height="100%">
          <v-card-title class="msaBlue white--text">
            Tasks
            <v-badge
              inline
              class="ml-2"
              :content="tasks.length"
              :value="tasks.length"
              color="red"
            >
            </v-badge>
          </v-card-title>
          <v-card-text class="px-0">
            <v-virtual-scroll
              :items="tasks"
              :height="height"
              :item-height="twoLineHeight"
            >
              <template v-slot:default="{ item, index }">
                <v-list-item :key="index" @click="goToTask(item)">
                  <v-list-item-action v-if="isAllowedToUpdateTask">
                    <v-checkbox
                      v-if="item.taskTypeId == $constants.TASK_TYPES.REGULAR"
                      @click.stop="updateTask(item)"
                      v-model="item.isDone"
                    ></v-checkbox>
                    <v-icon :color="iconColor(item)" v-else>
                      {{ getIcon(item) }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title
                      class="font-weight-bold text-body-2"
                      :class="isItemOverDue(item) ? 'red--text' : ''"
                    >
                      {{ item.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      class="font-italic text-caption"
                      :class="isItemOverDue(item) ? 'red--text' : ''"
                    >
                      <span v-if="item.dueDate != ''"> Due Date:</span>
                      <br v-else />
                      {{ item.dueDate | filterAsLocalDate }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-list-item-action>
                </v-list-item>
                <v-divider></v-divider>
              </template>
            </v-virtual-scroll>
          </v-card-text>
          <v-card-actions class="msaBlue">
            <v-btn text dark block @click="goToTasks()" class="text-right">
              <v-spacer></v-spacer> view all <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3" v-if="courses.length">
        <v-card height="100%">
          <v-card-title class="msaBlue white--text">
            Courses
            <v-badge
              inline
              class="ml-2"
              :content="courses.length"
              :value="courses.length"
              color="red"
            >
            </v-badge>
          </v-card-title>
          <v-card-text class="px-0">
            <v-virtual-scroll
              :items="courses"
              :height="height"
              :item-height="twoLineHeight"
            >
              <template v-slot:default="{ item, index }">
                <v-list-item :key="index" @click="goToCourse(item)">
                  <v-list-item-content>
                    <v-list-item-title
                      class="font-weight-bold text-body-2"
                      :class="isItemOverDue(item) ? 'red--text' : ''"
                    >
                      {{ item.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      class="font-italic text-caption"
                      :class="isItemOverDue(item) ? 'red--text' : ''"
                    >
                      <span v-if="item.dueDate != ''"> Due Date:</span>
                      <br v-else />
                      {{ item.dueDate | filterAsLocalDate }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-list-item-action>
                </v-list-item>
                <v-divider></v-divider>
              </template>
            </v-virtual-scroll>
          </v-card-text>
          <v-card-actions class="msaBlue">
            <v-btn text dark block @click="goToCourses()" class="text-right">
              <v-spacer></v-spacer> view all <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col>
        <v-card
          :style="{
            height: '75vh',
            display: 'flex',
            'justify-content': 'center',
            'align-content': 'center',
            'flex-direction': 'column',
          }"
        >
          <v-img
            src="../assets/no_rma.png"
            width="100%"
            height="100%"
            contain
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { DateTime } from 'luxon';
import {
  phantomAccessCheck,
  urlOrganizationCheck,
} from '../global/functions.js';
export default {
  name: 'RequiringMyAttention',
  computed: {
    isItemOverDue() {
      return (item) => {
        const jsDate = new Date(item.dueDate);
        return DateTime.now() > DateTime.fromJSDate(jsDate);
      };
    },
    isAllowedToUpdateTask() {
      if (this.$store.getters.isGroupAdministrator) {
        return true;
      }

      if (this.$store.getters.isWebAdmin) {
        return true;
      }

      return false;
    },
  },
  data() {
    return {
      forms: [],
      documents: [],
      tasks: [],
      courses: [],
      height: 0,
      twoLineHeight: 60,
      threeLineHeight: 75,
    };
  },
  methods: {
    getRMA() {
      const url = 'get-requiring-my-attention-items?format=json';
      this.$axios.post(url, { loaderText: 'loading...' }).then((response) => {
        this.forms = response.data.forms;
        this.documents = response.data.documents;
        this.tasks = response.data.tasks;
        this.courses = response.data.courses;
        if (
          this.forms.length ||
          this.documents.length ||
          this.tasks.length ||
          this.courses.length
        ) {
          this.$store.commit('updateRequiringMyAttention', 1);
        } else {
          this.$store.commit('updateRequiringMyAttention', 0);
        }
        this.$store.commit('updateRmaLastCheck', DateTime.now());
        this.setHeight();
      });
    },
    setHeight() {
      // These components has a fixed height regardless the screen size.
      const headerHeight = document.getElementById('cht-menu-nav').offsetHeight;
      const footerHeight = document.getElementById('cht-footer').offsetHeight;
      const cardTitleHeight = 76;
      const cardActionHeight = 64;
      const windowHeight = window.innerHeight - 20;

      this.height =
        windowHeight -
        headerHeight -
        footerHeight -
        cardTitleHeight -
        cardActionHeight;

      if (this.height < this.threeLineHeight * 6) {
        this.height = this.threeLineHeight * 6;
      }
    },
    goToForm(form) {
      this.$store.commit('updateSelectedFormInstance', {
        name: form.userEnteredLabel,
      });
      this.$router.push({ name: 'FormDetails', params: { id: form.id } });
    },
    goToDocument(document) {
      this.$store.commit(
        'updateSelectedAssignedDocument',
        JSON.parse(JSON.stringify(document)),
      );

      this.$router.push({
        name: 'AssignedDocumentDetails',
        params: { id: document.id },
      });
    },
    goToTask(task) {
      this.$router.push({
        name: 'Tasks',
        params: { taskId: task.id },
        query: { taskName: task.name },
      });
    },
    async goToCourse(course) {
      var windowReference = window.open();
      let respose = await this.$axios.post('get-transfer-token?format=json', {
        loaderText: 'Connecting....',
      });
      const url = `${process.env.VUE_APP_BASE_URL}security-service/cht-to-lms?format=json&redirect=EmployeeCourse&id=${course.id}&token=${respose.data}`;
      windowReference.location = url;
    },
    goToForms() {
      this.$router.push({ name: 'Forms', query: { requiringMyAttention: 1 } });
    },
    goToDocuments() {
      this.$router.push({
        name: 'AssignedDocuments',
        query: { requiringMyAttention: 1 },
      });
    },
    goToTasks() {
      this.$router.push({
        name: 'Tasks',
        query: { taskStatusIds: [2, 3].toString() },
      });
    },
    iconColor(task) {
      let color = '';
      switch (task.taskTypeId) {
        case this.$constants.TASK_TYPES.DOCUMENT:
          color = 'red';
          break;
        case this.$constants.TASK_TYPES.TRAINING:
          color = 'msaBlue';
          break;
        default:
          color = 'green';
      }
      return color;
    },
    getIcon(task) {
      let icon = '';
      switch (task.taskTypeId) {
        case this.$constants.TASK_TYPES.DOCUMENT:
          icon = 'mdi-file-pdf-box';
          break;
        case this.$constants.TASK_TYPES.TRAINING:
          icon = 'mdi-school';
          break;
        default:
          icon = '';
      }
      return icon;
    },
    updateTask(task) {
      const updateParams = {
        loaderText: 'loading...',
        id: task.id,
        snackText: 'Task Completed. Go to Training to reverse this action.',
      };

      const url = 'update-task-is-done?format=json';

      this.$axios.post(url, updateParams).then(() => {
        this.tasks = this.tasks.filter((t) => t.id != task.id);
      });
    },
    async goToCourses() {
      var windowReference = window.open();
      let respose = await this.$axios.post('get-transfer-token?format=json', {
        loaderText: 'Connecting....',
      });
      const url = `${process.env.VUE_APP_BASE_URL}security-service/cht-to-lms?format=json&redirect=EmployeeMyCourses&statuses=2,1&token=${respose.data}`;
      windowReference.location = url;
    },
  },
  mounted() {
    if (!phantomAccessCheck(this, 'Requiring My Attention')) {
      return;
    }
    urlOrganizationCheck(this);
    this.getRMA();
  },
  created() {
    window.addEventListener('resize', this.setHeight);
  },
  destroyed() {
    window.removeEventListener('resize', this.setHeight);
  },
};
</script>

<style></style>
